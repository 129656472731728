import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DishTypeSlider from "./carta/DishTypeSlider";
import "./Home.css";

const Home = ({ menu, whatsappNumber, email }) => {
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Filtrar los menús para incluir solo los que tienen videos
  const filteredMenu = menu
    .map((menuItem) => ({
      ...menuItem,
      dishes: menuItem.dishes.filter((dish) => dish["tiene-video"]),
    }))
    .filter((menuItem) => menuItem.dishes.length > 0);

  return (
    <div className="home">
      <DishTypeSlider
        menu={filteredMenu}
        whatsappNumber={whatsappNumber}
        email={email} // Pasamos el email al DishTypeSlider
      />
    </div>
  );
};

// Agregamos PropTypes
Home.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  whatsappNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  email: PropTypes.string,
};

export default Home;
