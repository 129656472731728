import React from "react";
import PropTypes from "prop-types";
import getIcon from "../../utils/getIcon";
import HeartIcon from "../../icons/HeartIcon";
import MutedIcon from "../../icons/MutedIcon";
import SoundIcon from "../../icons/SoundIcon";
import MailIcon from "../../icons/MailIcon";
import WhatsAppButton from "../WhatsAppButton";
import "./DishContent.css";

const DishContent = ({
  dish,
  dish_id,
  icon,
  dishType,
  toggleLike,
  liked,
  likes,
  animate,
  formatLikes,
  muted,
  toggleMuted,
  whatsappNumber,
  email,
}) => {
  const handleEmailClick = () => {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };

  return (
   
    
    <div className="dish-content">
       <div className="dish-contact">
       <div className="contact-buttons">
          {email && (
            <button onClick={handleEmailClick} className="email-button">
              <MailIcon className="mail-icon" />
            </button>
          )}
          {whatsappNumber && whatsappNumber !== "0" && (
            <WhatsAppButton whatsappNumber={whatsappNumber} />
          )}
        </div>
    </div> 
      <div className="dish-subcontent">   
        <div className="dish-item-left">
          <h2
            className="dish-title"
            dangerouslySetInnerHTML={{ __html: dish.dishName }}
          />
          {dish.content && (
            <div
             className="dish-content-detail"
             dangerouslySetInnerHTML={{ __html: dish.content }}
            />
         )}
           {dish.allergens && dish.allergens.length > 0 && (
             <div className="allergens">
                {dish.allergens.map((allergen, index) => (
                  <img
                  key={index}
                  src={getIcon(allergen)}
                  alt={allergen}
                  className="allergen-icon"
               />
                ))}
            </div>
            )}
        </div>
        <div className="dish-item-right">
          <p className="dish-price">
            {dish.price.toFixed(2)}
            <span>€</span>
          </p>
          <div className="like-container">
            <div
              className={`like-icon ${animate ? "animate" : ""}`}
              onClick={toggleLike}
              >
              <HeartIcon filled={liked} />
              {animate && <div className="floating-heart">❤️</div>}
            </div>
            <div className="like-count">{formatLikes(likes)}</div>
          </div>
          {dish["video-con-sonido"] && (
           <button onClick={toggleMuted} className="mute-button">
             {muted ? (
                <MutedIcon className="mute-icon" />
              ) : (
                <SoundIcon className="mute-icon" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

DishContent.propTypes = {
  dish: PropTypes.shape({
    dishName: PropTypes.string.isRequired,
    allergens: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.number,
    content: PropTypes.string,
    "video-con-sonido": PropTypes.bool,
  }).isRequired,
  dish_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  dishType: PropTypes.string,
  toggleLike: PropTypes.func.isRequired,
  liked: PropTypes.bool.isRequired,
  likes: PropTypes.number.isRequired,
  animate: PropTypes.bool.isRequired,
  formatLikes: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
  toggleMuted: PropTypes.func.isRequired,
  whatsappNumber: PropTypes.string.isRequired,
  email: PropTypes.string,
};

export default DishContent;
