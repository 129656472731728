// src/components/menu/MenuSection.js
import React from "react";
import "./MenuSection.css";

const MenuSection = ({ menu }) => {
  // Función auxiliar para convertir el order/orden a número
  const getOrderValue = (item, isCategory = false) => {
    const orderValue = isCategory ? item.orden : item.order;
    if (typeof orderValue === "string") {
      const numValue = parseInt(orderValue, 10);
      return isNaN(numValue) ? 0 : numValue;
    }
    return typeof orderValue === "number" ? orderValue : 0;
  };

  return (
    <section>
      <br />
      {menu
        .filter((menuItem) => menuItem.menu_activo)
        .sort((a, b) => getOrderValue(a) - getOrderValue(b)) // Ordenar menús por "order"
        .map((menuItem, menuIndex) => (
          <div className="contenedor-menu" key={menuIndex}>
            <h2 className="menu-title">{menuItem.nombre}</h2>
            {menuItem.categoria
              .sort((a, b) => getOrderValue(a, true) - getOrderValue(b, true)) // Ordenar categorías por "orden"
              .map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <h3>{category.nombre}</h3>
                  <ul>
                    {category.platos
                      .sort((a, b) => getOrderValue(a) - getOrderValue(b)) // Ordenar platos por "order"
                      .map((dish, dishIndex) => (
                        <li className="dish-wrapper" key={dishIndex}>
                          <div className="menu-dish-item">
                            <div className="menu-dish-name">
                              <span className="menu-dish-name">
                                {dish.dishName}
                              </span>
                              {dish.allergens && dish.allergens.length > 0 && (
                                <span className="menu-alergenos">
                                  {" a.: "}
                                  {dish.allergens.join(", ")}
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            <h4 className="menu-price">{menuItem.precio}€</h4>
          </div>
        ))}
    </section>
  );
};

export default MenuSection;
