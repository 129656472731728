import React, { useState, useEffect } from "react";
import cartabasicaIcon from "../../icons/arrow.svg";
import getIcon from "../../utils/getIcon";
import MenuSection from "../menu/MenuSection";
import CartaSection from "../menu/CartaSection";
import "./CartaBasica.css";

const CartaBasica = ({ onClose, restaurant, restaurant_id }) => {
  const [menuData, setMenuData] = useState(null);
  const [cartaData, setCartaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch menu data
        const menuResponse = await fetch(
          `${process.env.REACT_APP_API_URL}${restaurant_id}/?type=menu`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
            },
          }
        );

        if (!menuResponse.ok) {
          throw new Error("Error fetching menu data");
        }

        // Fetch carta data
        const cartaResponse = await fetch(
          `${process.env.REACT_APP_API_URL}${restaurant_id}/?type=carta`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
            },
          }
        );

        if (!cartaResponse.ok) {
          throw new Error("Error fetching carta data");
        }

        const menuResult = await menuResponse.json();
        const cartaResult = await cartaResponse.json();

        console.log("Menu Data:", menuResult);
        console.log("Carta Data:", cartaResult);

        setMenuData(menuResult);
        // Asegurarse de que cartaData sea un array
        setCartaData(cartaResult?.carta || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [restaurant_id]);

  if (loading) {
    return (
      <div className="carta-basica">
        <div className="carta-container">
          <div>Cargando datos...... un momento</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="carta-basica">
        <div className="carta-container">
          <div>Error fetching data: {error.message}</div>
        </div>
      </div>
    );
  }

  if (!menuData?.menu || !cartaData) {
    return (
      <div className="carta-basica">
        <div className="carta-container">
          <div>No data available</div>
        </div>
      </div>
    );
  }

  console.log("Restaurant:", restaurant);
  console.log("Logo dark:", restaurant["logo-dark"]);
  console.log("Menu:", menuData.menu);
  console.log("Carta:", cartaData);

  const RestaurantLogo = getIcon(restaurant["logo-dark"]);

  return (
    <div className="carta-basica">
      <div className="carta-container">
        <img
          src={cartabasicaIcon}
          alt="Carta Video"
          className="close-button"
          onClick={onClose}
        />
        <header className="carta-header">
          {RestaurantLogo ? (
            <RestaurantLogo className="restaurant-image" />
          ) : (
            <div>No logo available</div>
          )}
        </header>
        <MenuSection menu={menuData.menu} />
        <CartaSection carta={cartaData} />
      </div>
    </div>
  );
};

export default CartaBasica;
