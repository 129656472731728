// src/utils/getIcon.js

import { ReactComponent as LogoLight } from "../icons/restaurants/logo-light.svg"; // Ajustado path
import { ReactComponent as LogoDark } from "../icons/restaurants/logo-dark.svg"; // Ajustado path
import { ReactComponent as IconSugerencias } from "../icons/dishTypeIcons/icon-sugerencias.svg";
import { ReactComponent as IconTablas } from "../icons/dishTypeIcons/icon-tablas.svg";
import { ReactComponent as IconSandwiches } from "../icons/dishTypeIcons/icon-sandwiches.svg";
import { ReactComponent as IconEnsaladas } from "../icons/dishTypeIcons/icon-ensaladas.svg";
import { ReactComponent as IconBocadillos } from "../icons/dishTypeIcons/icon-bocadillos.svg";
import { ReactComponent as IconTostas } from "../icons/dishTypeIcons/icon-tostas.svg";
import { ReactComponent as IconHamburguer } from "../icons/dishTypeIcons/icon-hamburguer.svg";
import { ReactComponent as IconVeganas } from "../icons/dishTypeIcons/icon-veganas.svg";
import { ReactComponent as IconCremas } from "../icons/dishTypeIcons/icon-cremas.svg";
import { ReactComponent as IconWaffles } from "../icons/dishTypeIcons/icon-waffles.svg";
import { ReactComponent as IconJugos } from "../icons/dishTypeIcons/icon-jugos.svg";
import { ReactComponent as IconSmoothies } from "../icons/dishTypeIcons/icon-jugos1.svg";
import { ReactComponent as IconCaprichos } from "../icons/dishTypeIcons/icon-caprichos.svg";
import { ReactComponent as IconPostres } from "../icons/dishTypeIcons/icon-postres.svg";
import { ReactComponent as IconCarnes } from "../icons/dishTypeIcons/icon-carnes.svg";
import { ReactComponent as IconPescados } from "../icons/dishTypeIcons/icon-pescados.svg";
import { ReactComponent as IconMariscos } from "../icons/dishTypeIcons/icon-mariscos.svg";
import { ReactComponent as IconSopas } from "../icons/dishTypeIcons/icon-sopas.svg";
import { ReactComponent as IconDesayunos } from "../icons/dishTypeIcons/icon-desayunos.svg";

// Importar los iconos de alérgenos
import altramucesIcon from "../icons/allergenIcons/altramuces.svg";
import apioIcon from "../icons/allergenIcons/apio.svg";
import cacahuetesIcon from "../icons/allergenIcons/cacahuetes.svg";
import cascaraIcon from "../icons/allergenIcons/cascara.svg";
import glutenIcon from "../icons/allergenIcons/gluten.svg";
import sesamoIcon from "../icons/allergenIcons/sesamo.svg";
import crustaceosIcon from "../icons/allergenIcons/crustaceos-mariscos.svg";
import moluscosIcon from "../icons/allergenIcons/moluscos-caracoles.svg";
import lacteosIcon from "../icons/allergenIcons/lacteos.svg";
import mostazaIcon from "../icons/allergenIcons/mostaza.svg";
import sulfitosIcon from "../icons/allergenIcons/dioxido-azufre.svg";
import sojaIcon from "../icons/allergenIcons/soja.svg";
import huevosIcon from "../icons/allergenIcons/huevos.svg";
import pescadoIcon from "../icons/allergenIcons/pescado.svg";

const iconMapping = {
  // Logos - mapeados exactamente como vienen en el payload
  "logo-light": LogoLight,
  "logo-dark": LogoDark,

  // Iconos de tipos de platos
  "icon-sugerencias": IconSugerencias,
  "icon-tablas": IconTablas,
  "icon-sandwiches": IconSandwiches,
  "icon-ensaladas": IconEnsaladas,
  "icon-bocadillos": IconBocadillos,
  "icon-tostas": IconTostas,
  "icon-hamburguer": IconHamburguer,
  "icon-veganas": IconVeganas,
  "icon-cremas": IconCremas,
  "icon-waffles": IconWaffles,
  "icon-jugos": IconJugos,
  "icon-smoothies": IconSmoothies,
  "icon-caprichos": IconCaprichos,
  "icon-postres": IconPostres,
  "icon-carnes": IconCarnes,
  "icon-pescados": IconPescados,
  "icon-mariscos": IconMariscos,
  "icon-sopas": IconSopas,
  "icon-desayunos": IconDesayunos,

  // Iconos de alérgenos - ajustados para coincidir con los valores del payload
  gluten: glutenIcon,
  huevos: huevosIcon,
  lacteos: lacteosIcon,
  pescado: pescadoIcon,
  crustaceos: crustaceosIcon,
  moluscos: moluscosIcon,
  cascara: cascaraIcon,
  sulfitos: sulfitosIcon,
  soja: sojaIcon,
  mostaza: mostazaIcon,
  altramuces: altramucesIcon,
  apio: apioIcon,
  cacahuetes: cacahuetesIcon,
  sesamo: sesamoIcon,
};

const getIcon = (iconName) => {
  return iconMapping[iconName] || null;
};

export default getIcon;
