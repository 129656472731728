// src/components/menu/CartaSection.js
import React from "react";
import "./CartaSection.css";

const CartaSection = ({ carta }) => {
  return (
    <section>
      <h2 className="menu-title">Carta</h2>
      {carta.map((cartaItem, cartaIndex) => (
        <div key={cartaIndex}>
          <h3>{cartaItem.dishType}</h3>
          <ul>
            {cartaItem.dishes.map((dish, dishIndex) => (
              <li className="dish-wrapper" key={dishIndex}>
                <div className="menu-dish-item">
                  <div className="menu-dish-info">
                    <span className="menu-dish-name">{dish.dishName}</span>
                    {dish.content && (
                      <span
                        className="menu-dish-content"
                        dangerouslySetInnerHTML={{ __html: dish.content }}
                      />
                    )}
                    {dish.allergens && dish.allergens.length > 0 && (
                      <span className="menu-alergenos">
                        {" a.: "}
                        {dish.allergens.join(", ")}
                      </span>
                    )}
                  </div>
                  <div className="menu-dish-price">
                    {dish.price ? `${dish.price.toFixed(2)}€` : "N/A"}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
};

export default CartaSection;
