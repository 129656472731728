import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import ThumbsSlider from "./components/carta/ThumbsSlider";
import CartaBasica from "./components/carta/CartaBasica";
import Preloader from "./components/Preloader";
import useDocumentTitle from "./hooks/useDocumentTitle";
import "./App.css";

function App() {
  const [isCartaBasicaOpen, setIsCartaBasicaOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const restaurant_id = process.env.REACT_APP_RESTAURANT_ID;
  const apiUrl = process.env.REACT_APP_API_URL;
  const jwtToken = process.env.REACT_APP_JWT_TOKEN;

  const toggleCartaBasica = () => {
    setIsCartaBasicaOpen(!isCartaBasicaOpen);
  };

  useEffect(() => {
    const getMenuData = async () => {
      try {
        const response = await fetch(`${apiUrl}${restaurant_id}/?type=carta`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        // Check for a valid JSON response
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("The response is not JSON");
        }

        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    getMenuData();
  }, [apiUrl, jwtToken, restaurant_id]);

  // Set the document title when menu data is available
  useDocumentTitle(
    menuData ? `${menuData.restaurant.name} - Restaurante` : "Cargando..."
  );

  if (!menuData) {
    return <Preloader message="Cargando datos..." />;
  }

  // Filter the menu to only include dishes that have videos
  const filteredMenu = menuData.carta
    .map((menuItem) => ({
      ...menuItem,
      dishes: menuItem.dishes.filter((dish) => dish["tiene-video"]),
    }))
    .filter((menuItem) => menuItem.dishes.length > 0);

  return (
    <Router>
      <div className="App">
        <Header
          restaurant={menuData.restaurant}
          onCartaClick={toggleCartaBasica}
          isCartaOpen={isCartaBasicaOpen}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                menu={filteredMenu}
                whatsappNumber={menuData.restaurant.whatsapp}
                email={menuData.restaurant.email}
              />
            }
          />
        </Routes>
        {!isCartaBasicaOpen && <ThumbsSlider />}
        {isCartaBasicaOpen && (
          <CartaBasica
            restaurant={menuData.restaurant}
            restaurant_id={restaurant_id}
            onClose={toggleCartaBasica}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
