import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import PropTypes from "prop-types";
import "swiper/css";
import "swiper/css/pagination";
import Dish from "./Dish";
import "./DishSlider.css";

const DishSlider = ({
  dishes,
  icon = "",
  dishType = "",
  onSwiper,
  initialSlide = 0,
  whatsappNumber,
  email, // Agregado email como prop
}) => {
  const swiperRef = useRef(null);

  // Función para asegurar que el dish tenga la estructura correcta
  const formatDish = (dish) => {
    return {
      ...dish,
      dish_id: dish._id || dish.dish_id,
      likes: dish.likes || 0,
      "video-con-sonido": dish["video-con-sonido"] || false,
    };
  };

  return (
    <Swiper
      ref={swiperRef}
      direction="vertical"
      slidesPerView={1}
      spaceBetween={0}
      pagination={{ clickable: true }}
      modules={[Pagination]}
      className="vertical-slider"
      initialSlide={initialSlide}
      onSwiper={(swiper) => {
        onSwiper(swiper);
        swiperRef.current = swiper;
      }}
    >
      {dishes.map((dish, index) => {
        const formattedDish = formatDish(dish);

        return (
          <SwiperSlide key={index}>
            <Dish
              dish={formattedDish}
              icon={icon}
              dishType={dishType}
              whatsappNumber={whatsappNumber.toString()}
              email={email} // Pasando email al componente Dish
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

DishSlider.propTypes = {
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      dish_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      dishName: PropTypes.string.isRequired,
      content: PropTypes.string,
      price: PropTypes.number,
      likes: PropTypes.number,
      "video-con-sonido": PropTypes.bool,
      video: PropTypes.string,
      featuredImageLink: PropTypes.string,
      allergens: PropTypes.array,
    })
  ).isRequired,
  icon: PropTypes.string,
  dishType: PropTypes.string,
  onSwiper: PropTypes.func.isRequired,
  initialSlide: PropTypes.number,
  whatsappNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  email: PropTypes.string, // Agregado email a PropTypes
};

export default DishSlider;
