import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import DishContent from "./DishContent";
import VideoWithLoader from "./VideoWithLoader";
import "./Dish.css";

const API_URL = process.env.REACT_APP_API_URL;

const Dish = ({ dish, icon, dishType, whatsappNumber, email }) => {
  // Agregado email como prop
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(dish.likes || 0);
  const [animate, setAnimate] = useState(false);
  const [muted, setMuted] = useState(true);
  const videoRef = useRef(null);
  const syncTimeoutRef = useRef(null);

  const dishId = dish.dish_id || dish._id;
  const STORAGE_KEY = `dish-${dishId}`;

  const isIntersecting = useIntersectionObserver(videoRef, {
    threshold: 0.5,
  });

  // Cargar estado inicial desde localStorage
  useEffect(() => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      const { liked: storedLiked, likes: storedLikes } = JSON.parse(storedData);
      setLiked(storedLiked);
      setLikes(storedLikes);
    }
  }, [STORAGE_KEY]);

  // Función para sincronizar con el servidor
  const syncWithServer = async (newLikes) => {
    try {
      const response = await fetch(`${API_URL}public/updatelikes/${dishId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ likes: newLikes }),
      });

      if (!response.ok) {
        throw new Error(`Error al sincronizar likes: ${response.status}`);
      }

      const data = await response.json();
      console.log("Sincronización exitosa:", data);
    } catch (error) {
      console.error("Error en sincronización:", error);
      // Programar reintento
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
      syncTimeoutRef.current = setTimeout(() => syncWithServer(newLikes), 5000);
    }
  };

  // Función para actualizar localStorage
  const updateLocalStorage = (newLiked, newLikes) => {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        liked: newLiked,
        likes: newLikes,
        lastUpdated: Date.now(),
      })
    );
  };

  const toggleLike = () => {
    const newLiked = !liked;
    const newLikes = liked ? likes - 1 : likes + 1;

    setLiked(newLiked);
    setLikes(newLikes);
    setAnimate(true);

    updateLocalStorage(newLiked, newLikes);

    setTimeout(() => setAnimate(false), 700);

    if (syncTimeoutRef.current) {
      clearTimeout(syncTimeoutRef.current);
    }

    syncTimeoutRef.current = setTimeout(() => {
      syncWithServer(newLikes);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
    };
  }, []);

  const formatLikes = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "m";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    }
    return number;
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  if (!dish) {
    return <div className="dish">No data available</div>;
  }

  return (
    <div className="dish" ref={videoRef}>
      <div className="dish-video">
        <VideoWithLoader
          url={dish.video}
          poster={dish.featuredImageLink}
          playing={isIntersecting}
          muted={muted}
        />
      </div>
      <DishContent
        dish={dish}
        dish_id={dishId}
        icon={icon}
        dishType={dishType}
        toggleLike={toggleLike}
        liked={liked}
        likes={likes}
        animate={animate}
        formatLikes={formatLikes}
        muted={muted}
        toggleMuted={toggleMuted}
        whatsappNumber={whatsappNumber.toString()}
        email={email} // Pasando email como prop a DishContent
      />
    </div>
  );
};

Dish.propTypes = {
  dish: PropTypes.shape({
    _id: PropTypes.string,
    dish_id: PropTypes.string,
    dishName: PropTypes.string.isRequired,
    allergens: PropTypes.arrayOf(PropTypes.string), // Cambiado a array de strings
    price: PropTypes.number,
    video: PropTypes.string,
    content: PropTypes.string,
    featuredImageLink: PropTypes.string,
    likes: PropTypes.number,
    "video-con-sonido": PropTypes.bool,
  }).isRequired,
  icon: PropTypes.string,
  dishType: PropTypes.string,
  whatsappNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  email: PropTypes.string,
};

export default Dish;
